import React from 'react';
import { AiOutlineGithub, AiOutlineHeart } from 'react-icons/ai';
import { CgMediaLive } from 'react-icons/cg';


function ProjectCardWeb(item) {
  return (
      <div className='bg-white rounded-lg' style={{ width: "600px", height: "330px" }}>
          {/* img  */}
          <div style={{ width: "400px",height:'250px' }}  className=' relative bg-white p-4 rounded-lg'>
              <img className='h-full w-full object-contain rounded-lg mb-3' src={item?.image} alt={item?.title} />
              <div className='flex flex-col justify-center items-center space-y-2'>
                  <h1 className='text-gray-600 text-xl'>{item?.title}</h1>
                  {/* //live link  */}
                  <div className='flex flex-row items-center justify-center space-x-2 w-full'>
                      <a href={item?.live_link} target="_blank" rel="noopener">
                      <div className='cursor-pointer bg-white border py-1 px-3 rounded-full flex items-center flex-row space-x-1'>
                          <CgMediaLive className='text-xl text-red-600' />
                          <span className='text-red-600 text-sm font-light'>Live</span>
                      </div>
                      </a>

                      {/* //github code  */}
                      <a href={item?.github_link} target="_blank" rel="noopener">
                      <div className='cursor-pointer border border-gray-700 bg-gray-800 py-1 px-2 rounded-full flex items-center flex-row space-x-1'>
                          <AiOutlineGithub className='text-xl' />
                              <span className='text-white text-sm font-light'>Github Code</span>
                      </div>
                      </a>

                  </div>
              </div>

              <div className=' cursor-pointer bg-green-500 w-50 py-1 px-3 h-50 rounded-full right-5 top-5 absolute flex items-center flex-row space-x-2'>
                  <AiOutlineHeart className='text-3xl' />
                  <span className='text-white text-sm'>{item?.totalLoves}</span>
              </div>

             
          </div>
         

      </div>
  )
}

export default ProjectCardWeb