import React from 'react';
import styles from "../styles/About.module.css";
import SectionHead from './SectionHead';

function AboutSection() {
    return (
        <main className={styles?.about_banner}>
            <SectionHead title="About Me" />
            
            <div className='max-w-screen-lg mx-auto p-12'>

                {/* about me  */}
                <div className='flex flex-col justify-center items-center space-y-8'>
                    <div className=' bg-white rounded-lg overflow-hidden'>
                        <img className='object-cover w-96 h-64 border-4 border-white' src="../../images/3.jpg" alt="kabid" />
                    </div>

                    <div className='col-span-2'>
                        <p className='text-white  text-justify leading-8'>This is <span className='text-3xl font-bold'>Abtahi</span> who is a passionate student of <span className='text-xl font-semibold'>10th Grade</span> studying at Dhaka Residential Model College. Since my childhood, I had a great passion for drawing, and when I was a little kid even before admitting to the play grade, literally, I took pencil and color to dive into the drawing sector and express my imagination and thoughts through drawing. An interesting thing, I learned how to draw before learning the alphabet. Then since my drawing career has been started and go on. But I am too much interested in computers and even I got my hands on my first computer when I was in class 1. Till then, I used to with this magical stuff. When I was in 7-grade, I jumped into the programming sector and wrote my first code in HTML, it’s not a programming language though, which was “Hello World” that was printed on the web browser. Then gradually, I am upgrading myself by learning many more technologies whenever I need that and I have learned HTML, CSS, Javascript, React Js, Node js, Express Js, MongoDB Python etc. Moreover, I also try to use some libraries and frameworks to make my work instinctive and spontaneous. Eventually, I would say that I am an enthusiastic artist, designer, and programmer.</p>
                    </div>
                </div>
            </div>

            
        </main>
    );
}

export default AboutSection;