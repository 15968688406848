import React from 'react';
import { FullPage, Slide } from 'react-full-page';
import AboutSection from './components/AboutSection';
import HeroBanner from './components/HeroBanner';
import Navbar from './components/Navbar/Navbar';
import Projects from './components/Projects';

const App = () => {
  return (
    <main>
      <Navbar />
      <FullPage>
        <Slide><HeroBanner /></Slide>
        <Slide><AboutSection /></Slide>
        <Slide><Projects /></Slide>
        <Slide>
          <h1 className='text-4xl text-black text-center mt-24'>Website is under construction................ </h1>
        </Slide>
      </FullPage>
    </main>
  );
};

export default App;
