import React from 'react';
import { AiOutlineHeart } from 'react-icons/ai';

const ProjectCard = (item) => {
    return (
        <div>
            {/* img  */}
            <div className='w-full h-full relative'>
                <img src={item?.image} alt={item?.title} />

                <div className=' bg-green-500 w-50 py-1 px-3 h-50 rounded-full right-5 top-5 absolute flex items-center flex-row space-x-2'>
                    <AiOutlineHeart className='text-3xl' />
                    <span className='text-white text-sm'>{item?.totalLoves}</span>
                </div>
            </div>
            
        </div>
    );
};

export default ProjectCard;