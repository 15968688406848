import React from 'react';
import { AiFillBehanceSquare } from 'react-icons/ai';
import { BsFacebook, BsGithub, BsInstagram, BsLinkedin } from 'react-icons/bs';

const SocialContact = () => {
    const social_contact=[
        {id: 1, icon: <BsFacebook className='text-white' />, link:'https://www.facebook.com/abtahinoorsm/'},
        { id: 2, icon: <BsInstagram className='text-white' />, link:'https://www.instagram.com/abtahi_19/'},
        { id: 3, icon: <BsLinkedin className='text-white' />, link:'https://www.linkedin.com/in/smabtahinoor/'},
        { id: 4, icon: <BsGithub className='text-white' />, link:'https://www.github.com/19smabtahinoor/'},
        { id: 5, icon: <AiFillBehanceSquare className='text-white' />, link:'https://www.behance.net/abtahikabidnoor'},
    ]
    return (
        <div className='flex items-center space-x-3'>
            {social_contact?.map((index) => {
                return(
                    <>
                        <a className="text-white" href={index?.link} target="_blank" rel="noopener" key={index?.link}>{index?.icon}</a>
                    </> 
                )
            })}
        </div>
    );
};

export default SocialContact;