import React from 'react';

const SectionHead = ({title}) => {
    return (
        <div className='pt-16'>
            <h1 className='text-4xl text-center font-bold text-white '>{title}</h1>
            <div className='bg-green-500 w-14 h-2 mx-auto mt-2 rounded-md'></div>
        </div>
    );
};

export default SectionHead;