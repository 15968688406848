import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import styles from "../styles/HeroBanner.module.css";

const HeroBanner = () => {
    return (
        <main className={styles?.hero_banner}>
            
            <section className='max-w-screen-lg mx-auto'>
                {/* main section  */}
                <div className='flex flex-col justify-center items-center h-screen'>
                    {/* background image  */}
                    <img src="../../images/me.png" alt="me" className='w-48 h-48 rounded-xl' />

                    {/* main text  */}
                    <div className='flex flex-col space-y-2 justify-center items-center z-30'>
                        <h1 className={`${styles?.name_font} text-5xl md:text-6xl lg:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-green-300`}>S.M.Abtahi Noor</h1>

                        {/* type writer  */}
                        <TypeAnimation
                            sequence={[
                                'I am High School Student', // Types 'One'
                                1000, // Waits 1s
                                'I am MERN Stack Web Developer', 
                                2000, // Waits 2s
                                'I am Artist', 
                                3000, // Waits 2s
                                'I am Graphics Desinger', 
                                () => {
                                    console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                }
                            ]}
                            wrapper="div"
                            cursor={false}
                            repeat={Infinity}
                            className="text-white text-3xl"
                        />
                    </div>

                    {/* button  */}
                    <div className='pt-4'>
                        <a href="https://smabtahinoorold.vercel.app/" target="_blank" rel="noopener">
                            <button className='text-white rounded-full px-5 py-3 bg-gradient-to-br from-green-500 to-green-300 z-50 hover:bg-gradient-to-bl hover:from-green-300 hover:to-green-500 transition duration-500 '>Explore me</button>
                        </a>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default HeroBanner;