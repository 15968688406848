import React from 'react';
import SocialContact from './SocialContact';

const Navbar = () => {
    return (
        <nav className="fixed top-0 left-0 w-full px-24 py-8">
            <div className='flex justify-between items-center'> 
                {/* logo  */}
                <div>
                    <h1 className='text-white text-4xl'>Abtahi N.</h1>
                </div>

                {/* social icons  */}
                <SocialContact />
            </div>
        </nav>
    );
};

export default Navbar;