import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillBehanceSquare, AiOutlineGithub } from 'react-icons/ai';
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Projects.module.css";
import ProjectCard from './Projects Comp/ProjectCard';
import ProjectCardWeb from './Projects Comp/ProjectCardWeb';
import SectionHead from './SectionHead';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCards, EffectCoverflow, Pagination } from "swiper";


export default function Projects() {
  const [selectOption, setSelectOption] = useState('Web')
  const [projectDesign, setProjectDesign] = useState([])
  const [projectWeb, setProjectWeb] = useState([])

  //filter option
  const filterOption = [
    { id: 1, text: "Web" },
    { id: 2, text: "Designs" }
  ]

  //filter active and catch the value of the field
  const handleMenuChange = (type) => {
    setSelectOption(type)
  }

  //fetch the design  projects data 
  useEffect(() => {
    axios.get('/data/projects_designs.json').then(res => setProjectDesign(res.data))
  }, [])

  //fetch the web projects data
  useEffect(() => {
    axios.get('/data/projects_web.json').then(res => setProjectWeb(res.data))
  }, [] )




  return (
    <section className={styles?.projects_bg}>
      {/* heading  */}
      <SectionHead title="Projects" />

      {/* filter head */}
      <div className='flex flex-row items-center w-full justify-center space-x-6 mt-6'>
        <div className={`${selectOption === "Web" ? "border border-green-500 bg-green-500  scale-110" : "border border-white"} text-sm transform transition duration-300 text-white py-2 w-24 text-center rounded-md cursor-pointer`} onClick={() => setSelectOption('Web')}>Web({projectWeb?.length})</div>

        <div className={`${selectOption === "Designs" ? "border border-green-500 bg-green-500  scale-110" : "border border-white"} text-sm transform transition duration-300 text-white py-2 w-24 text-center rounded-md cursor-pointer`} onClick={() => setSelectOption('Designs')}>Designs({projectDesign?.length})</div>

      </div>

      {/* web projects section  */}
      {selectOption === "Web" && 
        <section className='max-w-screen-lg mx-auto pt-12'>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={false}
            modules={[EffectCoverflow, Pagination]}
            className="swiper_coverflow"
          >
            {projectWeb?.sort((a, b) => a?.serial_no - b?.serial_no)?.map(item => <SwiperSlide key={item?.title}><ProjectCardWeb {...item} /></SwiperSlide>)}
          </Swiper>
          
        
          {/* button  */}

          <a href="https://github.com/19smabtahinoor" target="_blank" rel="noopener">
            <div className='w-40 px-3 py-2 rounded-full mx-auto bg-white flex flex-row items-center justify-center space-x-1 mt-8'>
              <span className='text-black text-sm font-bold'>Explore on</span>
              <AiOutlineGithub className="text-gray-800 text-3xl" />
            </div>
          </a>
        </section>
      }

      {/* design projects section  */}
      {selectOption === "Designs" &&
        <section className='max-w-screen-lg mx-auto pt-12'>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {projectDesign?.sort((a, b) => a?.serial_no - b?.serial_no)?.map(item => <SwiperSlide key={item?.title}><ProjectCard {...item} /></SwiperSlide>)}
          </Swiper>

          {/* button  */}

          <a href="https://www.behance.net/abtahikabidnoor" target="_blank" rel="noopener">
            <div className='w-40 px-3 py-2 rounded-full mx-auto bg-white flex flex-row items-center justify-center space-x-1 mt-8'>
              <span className='text-blue-600 text-sm font-bold'>Explore on</span>
              <AiFillBehanceSquare className="text-blue-600 text-3xl" />
            </div>
          </a>
        </section>

      }


    </section>
  )
}
